import React from "react";
import { Checkbox as MantineCheckbox, CheckboxProps } from "@mantine/core";
import { merge } from "lodash";

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const defaults: CheckboxProps = {
    styles: {
      inner: { borderRadius: "2px !important" },
      input: { borderRadius: "2px !important", cursor: "pointer" },
    },
    classNames: { label: "cursor-pointer" },
  };

  const _props = merge(defaults, props);

  return <MantineCheckbox {..._props} />;
};

export default Checkbox;
