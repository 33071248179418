import React, { HTMLProps } from "react";

interface RedDotProps extends HTMLProps<HTMLDivElement> {
  count: number;
}

const RedDot: React.FC<RedDotProps> = ({ count, ...props }) => {
  return (
    <div className="absolute z-10 translate-x-4 translate-y-3" {...props}>
      <div
        className="bg-red-500 rounded-full
          border-2 border-solid border-dark-900
          children:text-white text-center"
      >
        <p className="text-white font-semibold leading-tight text-[0.75rem] px-1">
          {count > 9 ? `${count}+` : count}
        </p>
      </div>
    </div>
  );
};

export { RedDot };
