import {
  CreateProjectDto,
  QueryProjectDto,
  UpdateProjectDto,
} from "@server/modules/project/project/dto";
import {
  Collaborator,
  CollaboratorRole,
  Project,
} from "@server/entities/project";
import {
  useDelete,
  useGet,
  useMultipartForm,
  usePost,
  useStickySWR,
} from "../helpers";
import { PaginatedListDto } from "@server/dto/paginated-list.dto";
import { S3 } from "aws-sdk";

export const postProject = async (data: CreateProjectDto) =>
  useMultipartForm<Project>("/project", data);

export const starProject = async (id: string) =>
  usePost<null>(`/project/star/${id}`);

export const createShareableCode = async (
  id: string,
  shareableRole: CollaboratorRole
) => usePost<null>(`/project/share/${id}`, { shareableRole });

export const joinSharedProject = async (code: string) =>
  usePost<Collaborator>(`/project/join/${code}`);

export const updateProject = async (id: string, data: UpdateProjectDto) =>
  useMultipartForm<null>(`/project/${id}`, data);

export const updateProjectPost = async (id: string, data: UpdateProjectDto) =>
  usePost<null>(`/project/${id}`, data);

export const deleteProject = (id: string) => useDelete<null>(`/project/${id}`);

export const useProjects = (query?: QueryProjectDto) =>
  useStickySWR<PaginatedListDto<Project>>(`/project`, query);

export const getStarredProjects = () =>
  useStickySWR<Project[]>(`/project/starred`);

export const useProject = (permalink: string) =>
  useStickySWR<{ project: Project; role: Collaborator["role"] }>(
    `/project/${permalink}`
  );

export const getProject = (id: string) =>
  useGet<{ project: Project; role: Collaborator["role"] }>(`/project/id/${id}`);

export const checkPermalink = (permalink: string) =>
  useGet<boolean>(`/project/permalink/${permalink}`);

export const postGenerateProjectMixdown = async (id: string) =>
  usePost(`/project/mixdown/${id}/generate`);

export const uploadProjectMixdown = async (id: string, file: File) =>
  useMultipartForm(`/project/mixdown/${id}/upload`, {
    file,
    filekey: id,
    filetype: "umix",
  });

export const getProjectMixdownVersions = (id: string) =>
  useStickySWR<S3.ObjectVersionList>(`/project/mixdown/${id}`);
