import React from "react";
import { Popover as MantinePopover } from "@mantine/core";
import { defaults } from "@common/components/Menu";

const Popover: typeof MantinePopover = (props) => {
  return (
    <MantinePopover withinPortal {...defaults} {...props}>
      {props.children}
    </MantinePopover>
  );
};

Popover.Dropdown = MantinePopover.Dropdown;
Popover.Target = MantinePopover.Target;
Popover.displayName = MantinePopover.displayName;

export { Popover };
