import { ActionIcon, Button, DotsMenu, Modal } from "@common/components";
import useAsyncForm from "@common/utils/useAsyncForm";
import { TrashIcon } from "@heroicons/react/24/solid";
import { ModalProps, Tabs, Textarea } from "@mantine/core";
import { Cross1Icon } from "@modulz/radix-icons";
import { UpdateMarkupDto } from "@server/modules/project/markup/dto/update-markup.dto";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Markup } from "@server/entities/project";
import { MarkupDetails } from "../../modules/stemviewer/view/markup/components/MarkupDetails";
import { MarkupStatusSelect } from "../../modules/stemviewer/view/markup/components/MarkupStatusSelect";
import { MarkupDescription } from "../../modules/stemviewer/view/markup/components/MarkupDescription";
import { MarkupComments } from "../../modules/stemviewer/view/markup/components/MarkupComments";
import { MarkupLogs } from "../../modules/stemviewer/view/markup/components/MarkupLogs";
import { useTaskState } from "./task.selector";
import { getProject } from "../../requests/project/project";
import { useSetRecoilState } from "recoil";
import { projectState } from "./task.atom";
import { ChevronRight } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";

type MarkupModalProps = Omit<ModalProps, "opened"> & {
  markup?: Markup;
  mutate?: () => Promise<void>;
  onUpdate: (markup: UpdateMarkupDto) => Promise<void>;
  onDelete: () => Promise<void>;
};

const MarkupModal: React.FC<MarkupModalProps> = ({
  markup,
  mutate,
  onUpdate,
  onDelete,
  ...props
}) => {
  const navigate = useNavigate();
  const form = useAsyncForm<UpdateMarkupDto>({
    initialValues: {},
  });

  const [description, setDescription] = useState(form.values.description || "");
  const { project } = useTaskState(["project"]);
  const setProject = useSetRecoilState(projectState);

  useEffect(() => {
    const values = {
      ...markup,
      dueDate: markup?.dueDate ? dayjs(markup.dueDate).toDate() : undefined,
    } as UpdateMarkupDto;

    if (markup?.id && !form.values.id) {
      setDescription(values.description || "");
      return form.setValues(values, true);
    }
    if (markup?.stem?.id && !form.values.stem)
      return form.setValues(values, true);
    if (!markup) return form.setValues({}, true);
  }, [markup]);

  // Fetch project if not set
  useEffect(() => {
    if (markup?.projectId === project?.id || !markup?.projectId) return;

    getProject(markup.projectId).then(({ data }) =>
      setProject(data?.project || null)
    );
  }, [markup?.projectId, project]);

  const handleClose = async () => {
    if (!markup) return props.onClose();
    await onUpdate({
      ...form.values,
      description,
      reporterId: form.values.reporter?.id || null,
      assigneeId: form.values.assignee?.id || null,
    });
    props.onClose();
    form.setValues({}, true);
  };

  const handleDelete = async () => {
    if (!markup) return;
    await onDelete();
    props.onClose();
  };

  const handleGoTo = async () => {
    if (markup?.type === "markup") navigate(`/p/${project?.permalink}/viewer`);
    else navigate(`/p/${project?.permalink}/tasks`);
    await handleClose();
  };

  return (
    <Modal
      data-testid="task-modal"
      opened={!!markup}
      size="lg"
      {...props}
      onClose={handleClose}
    >
      <div className="flex gap-4">
        <div className="flex-1">
          <Textarea
            data-quick-assist-id="task-modal-title"
            data-testid="task-modal-title"
            {...form.getInputProps("title")}
            classNames={{
              input:
                "font-bold text-3xl bg-dark-900 pl-2 -mx-2 transition hover:bg-dark-900",
            }}
            styles={{
              input: { border: "1px solid transparent" },
            }}
            autosize
            minRows={1}
          />

          <div className="flex items-center justify-between gap-4 my-4">
            <MarkupStatusSelect {...form.getInputProps("status")} />
          </div>

          <MarkupDescription
            data-quick-assist-id="task-modal-description"
            value={description}
            onChange={setDescription}
          />
        </div>

        <div>
          <div className="flex flex-row-reverse gap-2 mb-6">
            <ActionIcon
              data-testid="task-modal-close"
              variant="transparent"
              onClick={handleClose}
            >
              <Cross1Icon />
            </ActionIcon>

            <DotsMenu data-testid="task-modal-dots-menu">
              <DotsMenu.Dropdown>
                <DotsMenu.Item
                  data-testid="task-modal-delete"
                  onClick={handleDelete}
                  icon={<TrashIcon className="w-4 h-4" />}
                  color="red"
                >
                  Delete markup
                </DotsMenu.Item>
              </DotsMenu.Dropdown>
            </DotsMenu>
          </div>

          <div className="flex flex-col gap-4">
            <Button
              variant="light"
              rightIcon={<ChevronRight className="w-5 h-5" />}
              onClick={handleGoTo}
            >
              Go to {markup?.type === "markup" ? "Stem Viewer" : "project"}
            </Button>
            <MarkupDetails form={form} />
          </div>
        </div>
      </div>

      <div className="mb-2">
        <b>Activity</b>
      </div>

      <Tabs
        variant="pills"
        defaultValue="comments"
        styles={{
          panel: { marginTop: 8 },
        }}
      >
        <Tabs.List>
          <Tabs.Tab value="comments">Comments</Tabs.Tab>
          <Tabs.Tab value="logs">Logs</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel data-quick-assist-id="task-modal-comments" value="comments">
          <MarkupComments markup={markup} mutate={mutate} />
        </Tabs.Panel>

        <Tabs.Panel data-quick-assist-id="task-modal-logs" value="logs">
          <MarkupLogs markup={markup} />
        </Tabs.Panel>
      </Tabs>
    </Modal>
  );
};

export { MarkupModal };
