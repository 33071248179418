import React, { ChangeEvent } from "react";
import { UseAsyncFormReturnType } from "@common/utils/useAsyncForm";
import { UpdateProjectDto } from "@server/modules/project/project/dto";
import InstagramSvg from "@res/images/instagram.svg";
import { Button, Input } from "@common/components";
import SpotifySvg from "@res/images/spotify.svg";
import TikTokSvg from "@res/images/tiktok.svg";
import YoutubeSvg from "@res/images/youtube.svg";
import SoundcloudSvg from "@res/images/soundcloud.svg";
import { LinkedAccount } from "@server/entities";
import { useUser } from "../../../../contexts/UserContext";
import { getUserByUsername } from "../../../../requests/user/user";

interface ProjectLinkedAccountsProps {
  form: UseAsyncFormReturnType<UpdateProjectDto>;
}

export const ProjectLinkedAccounts: React.FC<ProjectLinkedAccountsProps> = ({
  form,
}) => {
  const { user: me } = useUser();
  const { data: user } = getUserByUsername(me?.username as string);

  const handleUseMySocials = () => {
    form.setFieldValue("linkedAccounts", user?.linkedAccounts);
  };

  const handleChange =
    (type: LinkedAccount["type"]) =>
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (!form.values.linkedAccounts) {
        form.setFieldValue("linkedAccounts", [
          { type, url: target.value } as LinkedAccount,
        ]);
        return;
      }

      if (
        !form.values.linkedAccounts.some((account) => account.type === type)
      ) {
        form.setFieldValue(
          "linkedAccounts",
          form.values.linkedAccounts.concat({
            type,
            url: target.value,
          } as LinkedAccount)
        );
        return;
      }

      form.setFieldValue(
        "linkedAccounts",
        form.values.linkedAccounts?.map((account) => {
          if (account.type === type)
            return { ...account, url: target.value } as LinkedAccount;

          return account;
        })
      );
    };

  const getValue = (type: LinkedAccount["type"]) =>
    form.values.linkedAccounts?.find((account) => account.type === type)?.url;

  return (
    <div className="flex-1" data-quick-assist-id="project-linked-accounts">
      <div className="flex gap-4 justify-between mb-4">
        <div>
          <p className="font-semibold my-0">Socials</p>
          <p className="text-xs text-dark-300">
            Add links to your social media profiles
          </p>
        </div>

        {user?.linkedAccounts && user.linkedAccounts.length > 0 && (
          <Button variant="light" size="sm" onClick={handleUseMySocials}>
            Use my socials
          </Button>
        )}
      </div>

      <div className="flex items-center gap-4">
        <img className="w-5 h-5" src={InstagramSvg} alt="instagram" />
        <p className="w-24 m-0">Instagram</p>
        <Input
          className="flex-1"
          placeholder="https://www.instagram.com/..."
          value={getValue("instagram")}
          onChange={handleChange("instagram")}
          error={form.errors["linkedAccounts.0.url"]}
        />
      </div>

      <div className="flex items-center gap-4">
        <img className="w-5 h-5" src={SpotifySvg} alt="spotify" />
        <p className="w-24 m-0">Spotify</p>
        <Input
          className="flex-1"
          placeholder="https://open.spotify.com/artist/..."
          value={getValue("spotify")}
          onChange={handleChange("spotify")}
          error={form.errors["linkedAccounts.1.url"]}
        />
      </div>

      <div className="flex items-center gap-4">
        <img className="w-5 h-5" src={TikTokSvg} alt="tiktok" />
        <p className="w-24 m-0">TikTok</p>
        <Input
          className="flex-1"
          placeholder="https://www.tiktok.com/..."
          value={getValue("tiktok")}
          onChange={handleChange("tiktok")}
          error={form.errors["linkedAccounts.2.url"]}
        />
      </div>

      <div className="flex items-center gap-4">
        <img className="w-5 h-5" src={YoutubeSvg} alt="youtube" />
        <p className="w-24 m-0">Youtube</p>
        <Input
          className="flex-1"
          placeholder="https://www.youtube.com/..."
          value={getValue("youtube")}
          onChange={handleChange("youtube")}
          error={form.errors["linkedAccounts.3.url"]}
        />
      </div>

      <div className="flex items-center gap-4">
        <img className="w-5 h-5" src={SoundcloudSvg} alt="soundcloud" />
        <p className="w-24 m-0">Soundcloud</p>
        <Input
          className="flex-1"
          placeholder="https://www.soundcloud.com/..."
          value={getValue("soundcloud")}
          onChange={handleChange("soundcloud")}
          error={form.errors["linkedAccounts.4.url"]}
        />
      </div>
    </div>
  );
};
