import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Badge, BadgeProps, MantineColor } from "@mantine/core";
import { ClockIcon, ExclamationTriangleIcon } from "@modulz/radix-icons";
import { Markup } from "@server/entities/project";
import React from "react";

export interface MarkupStatusProps extends BadgeProps {
  value: Markup["status"];
}

type MarkupStatusOption = {
  label: string;
  value: Markup["status"];
  icon: React.ReactNode;
  color: MantineColor;
};

const options: MarkupStatusOption[] = [
  {
    label: "Todo",
    value: "pending",
    icon: <ClockIcon className="mt-[5px] w-3 h-3" />,
    color: "orange",
  },
  {
    label: "In progress",
    value: "needs_approval",
    icon: <ExclamationTriangleIcon className="mt-[5px] w-3 h-3" />,
    color: "yellow",
  },
  {
    label: "Done",
    value: "approved",
    icon: <CheckCircleIcon className="mt-[5px] w-3 h-3" />,
    color: "green",
  },
];

export const getMarkupColor = (status: Markup["status"]) => {
  const option = options.find((opt) => opt.value === status);
  return option?.color || "yellow";
};

const MarkupStatusBadge: React.FC<MarkupStatusProps> = ({
  value,
  ...props
}) => {
  const selected = options.find((opt) => opt.value === value) || options[0];

  return (
    <Badge
      {...props}
      color={selected.color}
      leftSection={selected.icon}
      radius={4}
    >
      {selected.label}
    </Badge>
  );
};

export { MarkupStatusBadge };
