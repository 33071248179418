import React, { KeyboardEventHandler, useCallback, useRef } from "react";
import { ActionIcon, Avatar, Card, Input } from "@common/components";
import { ArrowRightIcon } from "@modulz/radix-icons";
import { useUser } from "../../../../../../contexts/UserContext";
import useAsyncForm from "@common/utils/useAsyncForm";
import { CreateMarkupDto } from "@server/modules/project/markup/dto/create-markup.dto";
import { useProject } from "../../../ProjectContext";
import { createMarkup } from "../../../../../../requests/project/markup";
import { notification } from "@common/utils/notification";

interface CreateMarkupProps {
  start: number;
  end?: number;
  onClose: () => void;
}

export const CreateMarkup: React.FC<CreateMarkupProps> = ({
  start,
  end,
  onClose,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const { user } = useUser();
  const { project, currMixdownId, mutateMarkups } = useProject();

  const form = useAsyncForm<CreateMarkupDto>({
    initialValues: {
      projectId: project?.id,
      mixdownId: currMixdownId,
      start,
      end,
    } as CreateMarkupDto,
  });

  const handleSubmit = async () => {
    if (!form.values.title || form.values.title.trim() === "") return;
    const { data, error } = await form.sendForm(createMarkup);
    if (error) notification.error(error.message);
    if (data) await mutateMarkups();
    onClose();
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.key === "Enter") handleSubmit().catch();
    },
    [form]
  );

  return (
    <Card
      onPointerDown={(e) => e.stopPropagation()}
      className="w-64 p-2 rounded-2xl rounded-bl-none rounded-tl-3xl"
    >
      <div className="flex items-center gap-4">
        <Avatar className="ml-2 mt-1" size="sm" user={user} />

        <Input
          ref={ref}
          data-testid="create-markup-input"
          {...form.getInputProps("title")}
          autoFocus
          size="sm"
          className="-my-1"
          onKeyDown={handleKeyDown}
          onFocus={(e) => e.stopPropagation()}
        />

        <ActionIcon
          data-testid="create-markup-submit"
          className="rounded-xl mr-2"
          loading={form.loading}
          onClick={handleSubmit}
        >
          <ArrowRightIcon />
        </ActionIcon>
      </div>
    </Card>
  );
};
