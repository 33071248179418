import { FunctionComponent, memo, MemoExoticComponent } from "react";

export type WithContext = <T>(component: any) => FunctionComponent<T>;

/**
 * Chains context providers together
 * @param withContexts
 * @param component
 */
export const withContexts = <T>(
  withContexts: WithContext[],
  component: FunctionComponent<T>
): MemoExoticComponent<FunctionComponent<T>> => {
  let output = memo(component);
  for (const withContext of withContexts) {
    output = memo(withContext(output)) as MemoExoticComponent<
      FunctionComponent<T>
    >;
  }
  return output;
};
