import React from "react";
import { useProjects } from "../../../requests/project/project";
import { useUser } from "../../../contexts/UserContext";
import { Button } from "@common/components";
import { Link } from "react-router-dom";
import { Table } from "@mantine/core";
import { ProjectRow } from "../../projects/components/ProjectRow";
import { ChevronRight, Plus } from "tabler-icons-react";

export const RecentProjects: React.FC = () => {
  const { starred } = useUser();
  const { data } = useProjects({
    recent: true,
    sortBy: "updatedAt",
    dir: "DESC",
    limit: 6,
  });

  return (
    <div className="md:p-8" data-quick-assist-id="recent-projects-1">
      <div className="w-full flex flex-col md:flex-row items-start md:items-center justify-between gap-4 mb-8 p-2">
        <h2 className="text-3xl">Projects</h2>

        <Link
          to="/projects/create"
          data-testid="create-project"
          data-quick-assist-id="create-project"
        >
          <Button leftIcon={<Plus className="w-5 h-5" />}>
            Create project
          </Button>
        </Link>
      </div>

      {data ? (
        data.data.length > 0 ? (
          <div className="rounded overflow-hidden bg-dark-800">
            <Table className="w-full">
              <tbody>
                {starred.map((project) => (
                  <ProjectRow key={project.id} project={project} />
                ))}
                {data.data
                  .filter(
                    (project) => !starred.some((p) => p.id === project.id)
                  )
                  .map((project) => (
                    <ProjectRow
                      data-testid="project-card"
                      key={project.id}
                      project={project}
                    />
                  ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="text-center col-span-3 py-6">
            <p>No recent projects...</p>
            <Link to="/projects/create">
              <Button data-testid="create-project" variant="light">
                Create a project
              </Button>
            </Link>
          </div>
        )
      ) : null}

      {data && data.count > 1 && (
        <Link to="/projects">
          <div className="flex justify-end mt-8">
            <Button
              variant="light"
              color="dark"
              rightIcon={<ChevronRight className="w-5 h-5" />}
            >
              View more
            </Button>
          </div>
        </Link>
      )}
    </div>
  );
};
