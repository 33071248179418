import React, { createContext, useContext, useEffect, useState } from "react";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import { MarkupModal } from "./MarkupModal";
import { Markup } from "@server/entities";
import {
  deleteMarkup,
  getMarkup,
  updateMarkup,
} from "../../requests/project/markup";
import { notification } from "@common/utils/notification";
import { UpdateMarkupDto } from "@server/modules/project/markup/dto/update-markup.dto";
import { WithContext } from "../with-contexts";
import { CreateMarkupModal } from "./CreateMarkupModal";
import { CreateMarkupDto } from "@server/modules/project/markup/dto/create-markup.dto";

export interface ProjectContextType {
  viewMarkup: (id: string) => void;
  createTask: (initialValues?: CreateMarkupDto) => void;
  mutateTasks?: boolean;
}

const initialState = {} as ProjectContextType;

const TaskContext = createContext<ProjectContextType>(initialState);
TaskContext.displayName = "GlobalProjectContext";

export const useTasks = () => useContext(TaskContext);

export const withTaskContext: WithContext = (Component) => (props) => {
  const [query, setQuery] = useQueryParams({
    markupId: StringParam,
    createTask: BooleanParam,
  });
  const [markup, setMarkup] = useState<Markup | undefined>();
  const [createTaskInitialValues, setCreateTaskInitialValues] = useState<
    CreateMarkupDto | undefined
  >();
  const [mutateTasks, setMutateTasks] = useState(false);

  useEffect(() => {
    fetchMarkup();
  }, [query.markupId]);

  const fetchMarkup = async () => {
    try {
      if (!query.markupId) return;
      const { data } = await getMarkup(query.markupId);
      setMarkup(data);
    } catch (err) {
      notification.error(err.message);
    }
  };

  const handleClose = () => {
    setCreateTaskInitialValues(undefined);
    setQuery({ markupId: undefined });
    setMarkup(undefined);
    setMutateTasks((prev) => !prev);
  };

  const handleUpdate = async (update: UpdateMarkupDto) => {
    if (!markup) return;
    const { error } = await updateMarkup(markup.id, update);
    if (error) notification.error(error.message);
  };

  const handleDelete = async () => {
    if (!markup) return;
    const { error } = await deleteMarkup(markup.id);
    if (error) notification.error(error.message);
    handleClose();
  };

  return (
    <TaskContext.Provider
      value={{
        viewMarkup: (id) => setQuery({ markupId: id }),
        createTask: (initialValue) => {
          setCreateTaskInitialValues(initialValue);
          setQuery({ createTask: true });
        },
        mutateTasks,
      }}
    >
      <CreateMarkupModal
        initialValues={createTaskInitialValues}
        opened={!!query.createTask}
        onClose={() => setQuery({ createTask: undefined })}
        onCreate={() => setMutateTasks((prev) => !prev)}
      />

      <MarkupModal
        markup={markup}
        onClose={handleClose}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        mutate={fetchMarkup}
      />
      <Component {...props} />
    </TaskContext.Provider>
  );
};
