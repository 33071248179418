import React, { useMemo } from "react";
import { ActionIcon } from "@common/components/ActionIcon";
import { Popover } from "@mantine/core";
import { AddReaction } from "@mui/icons-material";
import { EmojiType, Reaction } from "@server/entities/project/reaction.entity";
import { Tooltip } from "@common/components/Tooltip";

interface EmojiSelectProps {
  value?: Reaction;
  reactions?: Reaction[];
  onEmojiClick: (data: { emoji: string; type: EmojiType }) => void;
}

const emojis: Array<{ emoji: string; type: EmojiType }> = [
  { emoji: "❤️", type: "heart" },
  { emoji: "🔥", type: "fire" },
  { emoji: "🎉", type: "party" },
  { emoji: "😭", type: "cry" },
  { emoji: "😂", type: "laugh" },
  { emoji: "👍", type: "thumbs-up" },
  { emoji: "👎", type: "thumbs-down" },
];

export const EmojiSelect: React.FC<EmojiSelectProps> = ({
  value,
  reactions = [],
  onEmojiClick,
}) => {
  const grouped = useMemo(() => {
    const groups: Array<{ type: EmojiType; count: number }> = [];

    reactions.forEach((item) => {
      const index = groups.findIndex((group) => group.type === item.type);
      if (index === -1) {
        groups.push({ type: item.type as EmojiType, count: 1 });
      } else {
        groups[index].count += 1;
      }
    });

    return groups;
  }, [value]);

  return (
    <div className="flex items-center gap-2">
      {grouped.map((item) => (
        <Tooltip
          key={item.type}
          label={
            <div className="flex flex-col gap-0.5">
              {reactions
                .filter((reaction) => reaction.type === item.type)
                .map((reaction) => (
                  <p key={reaction.userId} className="text-xs m-0">
                    {reaction.user?.displayName}
                  </p>
                ))
                .slice(0, 3)}

              {reactions.length > 3 && (
                <p className="text-xs m-0">+{item.count - 3} more</p>
              )}
            </div>
          }
        >
          <ActionIcon
            className="flex items-center w-fit px-3"
            variant={item.type === value?.type ? "light" : "outline"}
            color={item.type === value?.type ? "primary" : "dark"}
            radius="lg"
            onClick={() =>
              onEmojiClick({
                emoji: emojis.find((emoji) => emoji.type === item.type)!.emoji,
                type: item.type,
              })
            }
          >
            <div className="flex -gap-1 mr-2">
              {emojis.find((emoji) => emoji.type === item.type)?.emoji}
            </div>
            <span className="font-medium text-xs text-primary-300">
              {item.count}
            </span>
          </ActionIcon>
        </Tooltip>
      ))}

      <Popover position="bottom-start">
        <Popover.Target>
          <ActionIcon className="w-10" variant="outline" radius="lg">
            <AddReaction className="w-4 h-4" />
          </ActionIcon>
        </Popover.Target>

        <Popover.Dropdown>
          <div className="flex items-center gap-1">
            {emojis.map((item) => (
              <ActionIcon
                key={item.type}
                variant="subtle"
                color="dark"
                radius="lg"
                className="pt-2 hover:scale-125 transition"
                onClick={() => onEmojiClick(item)}
              >
                {item.emoji}
              </ActionIcon>
            ))}
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};
