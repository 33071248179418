import { clamp } from "lodash";

export function pixelsToTime(
  pixels: number,
  containerWidth: number,
  duration: number
) {
  return clamp(0, (pixels / containerWidth) * duration, duration);
}

export function timeToPixels(
  time: number,
  containerWidth: number,
  duration: number
) {
  return Math.round(
    clamp(0, (time / duration) * containerWidth, containerWidth)
  );
}
