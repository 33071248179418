import { Mixdown } from "@server/entities";
import React, { useEffect, useMemo, useState } from "react";
import { Howl } from "howler";
import { useWorker } from "@common/utils/use-worker";
import { useDebouncedValue, useElementSize, useHotkeys } from "@mantine/hooks";
import { stemHeight } from "../../../../../stemviewer/helpers/constants";
import { draw } from "../../ProjectMixdown";
import { useMantineTheme } from "@mantine/core";
import { clamp } from "lodash";
import { ActionIcon, Button, Card, Input } from "@common/components";
import { PauseRounded, PlayArrowRounded } from "@mui/icons-material";
import { useDrag } from "@use-gesture/react";
import moment from "moment/moment";
import { ChevronLeft } from "tabler-icons-react";

const createWorker = () =>
  new Worker(
    new URL(
      "../../../../../stemviewer/helpers/workers/get-audio-array-buffer",
      import.meta.url
    ),
    { type: "module" }
  );

export type MixdownCompare = Partial<Mixdown> & {
  howl?: Howl;
  buffer?: AudioBuffer;
  selected?: boolean;
};

interface MixdownCompareVersionsProps {
  mixdowns: MixdownCompare[];
  onClose: () => void;
}

export const MixdownCompareVersions: React.FC<MixdownCompareVersionsProps> = ({
  mixdowns: _mixdowns,
  onClose,
}) => {
  const { ref, width } = useElementSize();
  const [debouncedWidth] = useDebouncedValue(width, 1000);
  const [mixdowns, setMixdowns] = useState<Array<MixdownCompare>>(_mixdowns);
  const [playing, setPlaying] = useState(false);
  const [currTime, setCurrTime] = useState(0);
  const duration = Math.max(
    ...mixdowns.map((mixdown) => mixdown.duration || 0)
  );

  const scale = useMemo(
    () => (debouncedWidth !== 16 ? 4 / (debouncedWidth - 16) : undefined),
    [debouncedWidth]
  );

  // Play or pause the audio with spacebar
  useHotkeys([
    [
      "space",
      (event) => {
        event.preventDefault();
        handlePlayPause();
      },
    ],
  ]);

  // Unload howls on exit
  useEffect(() => {
    return () => {
      mixdowns.forEach((mixdown) => mixdown.howl?.unload());
    };
  }, []);

  // Rerender every 500ms
  useEffect(() => {
    if (!playing) return;

    const interval = setInterval(() => {
      const longestMixdown =
        (mixdowns[0].duration || 0) > (mixdowns[1].duration || 0) ? 0 : 1;
      setCurrTime(mixdowns[longestMixdown].howl?.seek() || 0);
    }, 500);

    return () => clearInterval(interval);
  }, [playing]);

  const handleMixdownSelect = (index: number) => {
    const selectedMixdown = mixdowns[index];
    mixdowns.forEach((mixdown) => {
      if (mixdown.id === selectedMixdown.id) {
        mixdown.howl?.volume(1);
      } else {
        mixdown.howl?.volume(0);
      }
    });
    setMixdowns((prev) =>
      prev.map((prevMixdown, i) => ({
        ...prevMixdown,
        selected: i === index,
      }))
    );
  };

  function handlePlayPause() {
    if (playing) {
      mixdowns.forEach((mixdown) => mixdown.howl?.pause());
    } else {
      mixdowns.forEach((mixdown) => {
        mixdown.howl?.seek(currTime);
        if (!mixdown.howl?.playing()) mixdown.howl?.play();
      });
    }
    setPlaying(!playing);
  }

  const seek = (time: number) => {
    setCurrTime(time);
    mixdowns.forEach((mixdown) => {
      mixdown.howl?.seek(time);
      if (playing && !mixdown.howl?.playing()) mixdown.howl?.play();
    });
  };

  return (
    <div
      data-quick-assist-id="mixdown-compare"
      ref={ref}
      className="rounded my-6 overflow-hidden relative"
    >
      <div
        className="absolute z-10 px-4 -top-2 -left-4 h-[110%] bg-indigo-700 opacity-80 mix-blend-color-dodge rounded touch-none pointer-events-none"
        style={{
          width:
            Math.round(clamp((currTime / duration) * (width - 16), 0, width)) +
            22,
          transition: "width 500ms linear",
        }}
      />

      <Button
        leftIcon={<ChevronLeft className="w-3 h-3" />}
        variant="light"
        color="gray"
        size="xs"
        className="absolute right-2 top-2 z-30"
        onClick={onClose}
      >
        Back
      </Button>

      <div>
        {mixdowns.map((mixdown, index) => (
          <MixdownComponent
            {...mixdown}
            key={mixdown.id}
            index={index}
            width={width}
            scale={scale || 1}
            duration={duration}
            onSelect={() => handleMixdownSelect(index)}
            onSeek={seek}
          />
        ))}
      </div>

      <Card className="relative z-20 flex items-center justify-center rounded-tr-none rounded-tl-none p-2">
        <div className="absolute flex items-center gap-2 left-2">
          <Input
            size="xs"
            classNames={{ input: "w-16 text-md text-center bg-dark-900" }}
            value={moment().startOf("day").seconds(currTime).format("mm:ss")}
          />
        </div>

        <ActionIcon
          variant={playing ? "light" : "filled"}
          color={playing ? "gray" : "primary"}
          onClick={handlePlayPause}
        >
          {playing ? <PauseRounded /> : <PlayArrowRounded />}
        </ActionIcon>
      </Card>
    </div>
  );
};

const MixdownComponent: React.FC<
  MixdownCompare & {
    index: number;
    width: number;
    scale: number;
    duration: number;
    onSelect: (index: number) => void;
    onSeek: (time: number) => void;
  }
> = ({
  name,
  buffer,
  index,
  selected,
  width,
  duration,
  scale,
  onSelect,
  onSeek,
}) => {
  const theme = useMantineTheme();

  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);

  const [{ result }] = useWorker<{ array: number[] }>(createWorker, {
    buffer: buffer?.getChannelData(0),
    duration,
    scale,
  });

  useEffect(() => {
    if (!canvas || !result) return;
    draw(canvas, result.array);
  }, [result]);

  const fromColor = index % 2 === 0 ? "indigo" : "purple";
  const toColor = index % 2 === 0 ? "rose" : "pink";

  // Play audio whenever user drags the needle
  const bind = useDrag(({ xy, down }) => {
    if (!down || !selected) return;
    const mixdownCanvas = document.getElementById("mixdown_canvas");
    const mixdownCanvasRect = mixdownCanvas?.getBoundingClientRect();
    if (!mixdownCanvasRect) return;

    const time = clamp(
      ((xy[0] - mixdownCanvasRect.left) / (width - 16)) * duration,
      0,
      duration
    );

    onSeek(time);
  }, {});

  return (
    <div
      className="relative p-1 shadow-2xl"
      style={{
        background: `linear-gradient(90deg, ${theme.colors[fromColor][7]}, ${theme.colors[toColor][7]})`,
        opacity: selected ? 1 : 0.5,
      }}
      {...bind()}
      onClick={() => onSelect(index)}
    >
      <p className="absolute left-2 top-2 font-semibold">{name}</p>

      <canvas
        id="mixdown_canvas"
        className="animate-opacity"
        ref={setCanvas}
        style={{
          margin: 8,
          height: stemHeight,
        }}
      />
    </div>
  );
};
