import React, { useState } from "react";
import { Avatar, Button, EmojiSelect, RichText } from "@common/components";
import dayjs from "dayjs";
import { Badge } from "@mantine/core";
import { getRandomColor } from "@common/utils/get-random-color";
import moment from "moment/moment";
import { RichTextReadOnly } from "@common/components/RichTextReadOnly";
import { Edit, Reply } from "@mui/icons-material";
import { Cross1Icon, TrashIcon } from "@modulz/radix-icons";
import { Markup, User } from "@server/entities";
import { useUser } from "../../../../contexts/UserContext";
import { usePlayer } from "../../../../contexts/Player";
import { updateMarkup } from "../../../../requests/project/markup";
import useAsyncForm from "@common/utils/useAsyncForm";
import { UpdateMarkupDto } from "@server/modules/project/markup/dto/update-markup.dto";
import { useProject } from "../ProjectContext";
import { EmojiType } from "@server/entities/project/reaction.entity";
import { postReaction } from "../../../../requests/project/reaction";
import { notification } from "@common/utils/notification";

interface ProjectCommentProps {
  comment: Markup;
  onDelete: (id: string) => void;
  onReply: (user: User) => void;
}

export const ProjectComment: React.FC<ProjectCommentProps> = ({
  comment,
  onDelete,
  onReply,
}) => {
  const { project, mutateMarkups } = useProject();
  const { user } = useUser();
  const { seek } = usePlayer();
  const [edit, setEdit] = useState(false);

  const form = useAsyncForm<UpdateMarkupDto>({
    initialValues: {
      title: comment.title,
    } as UpdateMarkupDto,
  });

  const handleSave = async () => {
    const { error } = await form.sendForm(
      (values) =>
        updateMarkup(comment.id, {
          title: values.title,
        }),
      {
        resetInitial: true,
      }
    );
    if (error) return;
    setEdit(false);
  };

  const handleReact = async ({ type }: { type: EmojiType }) => {
    const { error } = await postReaction(comment.id, {
      type,
      reactionFor: "markup",
    });
    if (error) return notification.error(error.message);
    await mutateMarkups();
  };

  return (
    <div>
      <div className="flex gap-4 items-start">
        <Avatar user={comment.reporter} />

        <div className="flex-1">
          <p className="text-indigo-400 font-medium m-0">
            {comment.reporter?.displayName}{" "}
            <span className="text-dark-400 font-normal ml-1">
              {dayjs(comment.createdAt).fromNow()}
            </span>
            {comment.mixdown && (
              <Badge
                className="ml-2"
                size="xs"
                color={getRandomColor(comment.mixdown.id)}
              >
                {comment.mixdown.name}
              </Badge>
            )}
          </p>

          <div>
            {comment.start && (
              <span
                onClick={() => comment.start && seek(comment.start)}
                className="bg-dark-500 text-indigo-400 text-xs font-medium px-1 py-0.5 rounded mr-2"
              >
                {moment().startOf("day").seconds(comment.start).format("m:ss")}
              </span>
            )}

            {edit ? (
              <div className="relative">
                <RichText
                  {...form.getInputProps("title")}
                  classNames={{ toolbar: "hidden" }}
                  className="mt-2 flex-1"
                  users={project?.collaborators
                    .map((c) => c.user)
                    .filter((c) => c.id !== user?.id)}
                />

                <Button onClick={handleSave} size="xs" className="mt-4">
                  Save
                </Button>
              </div>
            ) : (
              <RichTextReadOnly value={form.values.title || comment.title} />
            )}

            <div className="flex gap-2 mt-2 -ml-2">
              <EmojiSelect
                value={comment.reactions?.find(
                  (reaction) => reaction.userId === user?.id
                )}
                reactions={comment.reactions}
                onEmojiClick={handleReact}
              />

              <Button
                color="dark"
                variant="subtle"
                size="xs"
                leftIcon={<Reply className="w-3" />}
                onClick={() => comment.reporter && onReply(comment.reporter)}
              >
                Reply
              </Button>

              {comment.reporterId === user?.id && (
                <>
                  <Button
                    variant="subtle"
                    size="xs"
                    leftIcon={
                      edit ? (
                        <Cross1Icon className="w-3" />
                      ) : (
                        <Edit className="w-3" />
                      )
                    }
                    onClick={() => setEdit((edit) => !edit)}
                  >
                    {edit ? "Cancel" : "Edit"}
                  </Button>

                  <Button
                    color="red"
                    variant="subtle"
                    size="xs"
                    leftIcon={<TrashIcon className="w-3" />}
                    onClick={() => onDelete(comment.id)}
                  >
                    Delete
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
