import React, { useState } from "react";
import { UseAsyncFormReturnType } from "@common/utils/useAsyncForm";
import { UpdateProjectDto } from "@server/modules/project/project/dto";
import { ActionIcon, Button, Tooltip } from "@common/components";
import { Cross1Icon, ImageIcon } from "@modulz/radix-icons";
import { UploadImageModal } from "@common/components/UploadImageModal";
import { useScroll } from "@use-gesture/react";
import { useProject } from "../ProjectContext";

interface ProjectCoverProps {
  form: UseAsyncFormReturnType<UpdateProjectDto>;
}

export const ProjectCover: React.FC<ProjectCoverProps> = ({ form }) => {
  const { view, project } = useProject();
  const [uploadCover, setUploadCover] = useState(false);
  const [top, setTop] = useState(0);

  useScroll(
    ({ delta }) => {
      setTop((prev) => prev - delta[1] / 2);
    },
    {
      target: document.getElementById("scroll-container")!,
    }
  );

  return (
    <div>
      <div
        className="fixed left-0 w-full h-80"
        style={{
          background: "inherit",
          backgroundImage: `url(${
            form.values.coverArt || "/images/project-cover.png"
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          top,
        }}
      >
        <div className="w-full h-full bg-gradient-to-b from-transparent to-dark-1000" />

        <UploadImageModal
          opened={uploadCover}
          onClose={() => setUploadCover(false)}
          onUpload={(url) => form.setFieldValue("coverArt", url)}
          targetWidth={1920}
          targetHeight={640}
          projectId={project?.id}
        />
      </div>

      {view !== "viewer" ? (
        <div className="container relative">
          <div className="absolute top-0 right-0 flex items-center justify-end gap-2">
            <Button
              leftIcon={<ImageIcon />}
              size="xs"
              variant="light"
              color="gray"
              onClick={() => setUploadCover(true)}
            >
              Upload cover
            </Button>

            {form.values.coverArt && (
              <Tooltip label="Remove cover">
                <ActionIcon
                  size="sm"
                  variant="light"
                  color="red"
                  onClick={() =>
                    form.setFieldValue("coverArt", "/images/project-cover.png")
                  }
                >
                  <Cross1Icon />
                </ActionIcon>
              </Tooltip>
            )}
          </div>
        </div>
      ) : (
        <div className="my-8"></div>
      )}
    </div>
  );
};
