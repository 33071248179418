import { Avatar } from "@common/components";
import { useMantineTheme } from "@mantine/core";
import { Portal } from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import { Markup as MarkupEntity } from "@server/entities/project";
import { useHover } from "@use-gesture/react";
import { motion } from "framer-motion";
import { isEqual } from "lodash";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useStemViewer } from "../../contexts/StemViewerContext";
import { StemHowl } from "../../recoil/stem";
import {
  getMarkupColor,
  MarkupStatusBadge,
} from "./components/MarkupStatusBadge";
import { MarkupResize } from "./components/MarkupResize";

interface MarkupProps {
  markup: MarkupEntity;
  stems: StemHowl[];
}

const Markup = memo<MarkupProps>(
  ({ markup, stems }) => {
    const theme = useMantineTheme();
    const forceUpdate = useForceUpdate();
    const tries = useRef(0);
    const stem = useRef<HTMLElement | null>(null);
    useStemViewer().track(["scale"]);
    useStemViewer().stem(["loading"]);

    const { selected } = useStemViewer().markup(["selected"]);
    const { track, markup: markupCtl } = useStemViewer().controllers;

    const [hovered, setHovered] = useState(false);
    const bind = useHover(({ hovering }) => setHovered(!!hovering));

    const [endA, setEndA] = useState(false);
    const [endB, setEndB] = useState(true);

    useEffect(() => {
      let timeout = setTimeout(() => setHovered(selected === markup.id), 100);
      return () => clearTimeout(timeout);
    }, [selected, markup.id]);

    useEffect(() => {
      const activeStem = stems.find((stem) => stem.id === markup.stemId);
      stem.current = document.getElementById(`stem-${activeStem?.id}`);
      forceUpdate();
    }, [markup.stemId]);

    // Loop trying to find stem
    useEffect(() => {
      tries.current = 0;
      let timeout = setInterval(() => {
        if (tries.current >= 3 || stem.current) return clearInterval(timeout);
        tries.current += 1;
        const activeStem = stems.find((stem) => stem.id === markup.stemId);
        stem.current = document.getElementById(`stem-${activeStem?.id}`);
        if (stem.current) forceUpdate();
      }, 500);
      return () => clearInterval(timeout);
    }, [stems.length]);

    const handleClick = useCallback(() => markupCtl.view(markup.id), []);

    const handleChangeEnd = useCallback(
      (end: "A" | "B") => (isEnd: boolean) => {
        setEndA(end === "A" ? isEnd : !isEnd);
        setEndB(end === "A" ? !isEnd : isEnd);
      },
      [markup]
    );

    if (!stem.current) return <></>;

    return (
      <Portal target={stem.current}>
        <MarkupResize
          markup={markup}
          isEnd={endA}
          onChangeEnd={handleChangeEnd("A")}
        />
        <MarkupResize
          markup={markup}
          isEnd={endB}
          onChangeEnd={handleChangeEnd("B")}
        />

        <div
          data-testid="markup-region"
          className="absolute z-40 top-0 transition h-full pointer-events-auto"
          onDoubleClick={() =>
            markup.end &&
            track.region(
              track.timeToPixels(markup.start || 0),
              track.timeToPixels(markup.end - (markup.start || 0))
            )
          }
          style={{
            background: hovered ? "rgba(255,46,46,0.6)" : "rgba(255,46,46,0.4)",
            left: track.timeToPixels(markup.start || 0),
            width: markup.end
              ? track.timeToPixels(markup.end - (markup.start || 0))
              : 1,
          }}
        />

        <div
          {...bind()}
          id={markup.id}
          data-testid="sv-markup-bubble"
          data-quick-assist-id="sv-markup-bubble"
          className="absolute bottom-2 cursor-pointer"
          style={{
            left: track.timeToPixels(markup.end || markup.start || 0),
            zIndex: hovered ? 50 : 40,
          }}
          onClick={handleClick}
        >
          <motion.div
            className="relative bg-dark-900 rounded-[2rem] rounded-bl-none overflow-hidden"
            transition={{ type: "tween", duration: 0.15 }}
            style={{
              border: `1px solid ${theme.fn.rgba(
                theme.colors[getMarkupColor(markup.status)][4],
                0.6
              )}`,
            }}
            animate={{
              padding: hovered ? "1rem" : "0.5rem",
            }}
          >
            <div className="flex items-center gap-4 h-full">
              <Avatar
                className="transition duration-[0.15s]"
                size="sm"
                user={markup.reporter}
                style={{
                  transform: hovered
                    ? "translateY(-0.75rem)"
                    : "translateY(0rem)",
                }}
              />

              {hovered ? (
                <Avatar
                  placeholder
                  className="absolute translate-y-3"
                  size="sm"
                  user={markup.assignee}
                />
              ) : null}

              {hovered && (
                <motion.div
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, width: "auto", height: "auto" },
                    collapsed: { opacity: 0, width: 0, height: 0 },
                  }}
                  transition={{ type: "tween", duration: 0.15 }}
                >
                  <div className="flex flex-col gap-1">
                    <MarkupStatusBadge
                      className="mr-auto"
                      value={markup?.status}
                      size="xs"
                    />

                    <p className="text-[1rem] max-w-[250px] truncate">
                      {markup.title}
                    </p>
                  </div>
                </motion.div>
              )}
            </div>
          </motion.div>
        </div>
      </Portal>
    );
  },
  (prev, next) =>
    isEqual(prev.markup, next.markup) && prev.stems.length === next.stems.length
);

export { Markup };
