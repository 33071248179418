import { ActionIcon, DotsMenu, Modal } from "@common/components";
import useAsyncForm from "@common/utils/useAsyncForm";
import { TrashIcon } from "@heroicons/react/24/solid";
import { ModalProps, Tabs, Textarea } from "@mantine/core";
import { Cross1Icon } from "@modulz/radix-icons";
import { UpdateMarkupDto } from "@server/modules/project/markup/dto/update-markup.dto";
import React, { useEffect, useState } from "react";
import { useStemViewer } from "../../contexts/StemViewerContext";
import { MarkupComments } from "./components/MarkupComments";
import { MarkupDescription } from "./components/MarkupDescription";
import { MarkupDetails } from "./components/MarkupDetails";
import { MarkupStatusButton } from "./components/MarkupStatusButton";
import { useParams } from "react-router";
import { useProject } from "../../../projects/view/ProjectContext";
import { MarkupStatusSelect } from "./components/MarkupStatusSelect";
import { MarkupLogs } from "./components/MarkupLogs";
import dayjs from "dayjs";
import { Markup } from "@server/entities/project";

type MarkupModalProps = Omit<ModalProps, "opened">;

const MarkupModal: React.FC<MarkupModalProps> = ({ ...props }) => {
  const { markupId } = useParams();
  const { project } = useProject();
  const { view: markup } = useStemViewer().markup(["view"]);
  const { markup: markupCtl } = useStemViewer().controllers;

  const form = useAsyncForm<UpdateMarkupDto>({
    initialValues: {},
  });

  const [description, setDescription] = useState(form.values.description || "");

  useEffect(() => {
    const values = {
      ...markup,
      dueDate: markup?.dueDate ? dayjs(markup.dueDate).toDate() : undefined,
    } as UpdateMarkupDto;

    if (markup?.id && !form.values.id) {
      setDescription(values.description || "");
      return form.setValues(values, true);
    }
    if (markup?.stem?.id && !form.values.stem)
      return form.setValues(values, true);
    if (!markup) return form.setValues({}, true);
  }, [markup]);

  // Close modal if markup id is null
  useEffect(() => {
    if (markupId && project) markupCtl.view(markupId);
    if (!markupId) markupCtl.view(null);
  }, [markupId, project]);

  const handleClose = async () => {
    if (!markup) return props.onClose();
    if (!form.values.stem) return props.onClose();
    props.onClose();
    form.setValues({}, true);
    await markupCtl.update(markup.id, {
      ...form.values,
      description,
      reporterId: form.values.reporter?.id || null,
      assigneeId: form.values.assignee?.id || null,
      stemId: form.values.stem.id,
    });
  };

  const handleDelete = async () => {
    if (!markup) return;
    await markupCtl.delete(markup.id);
    props.onClose();
  };

  return (
    <Modal
      opened={!!(markupId && markup)}
      size="lg"
      {...props}
      onClose={handleClose}
    >
      <div className="flex gap-4">
        <div className="flex-1">
          <Textarea
            data-quick-assist-id="sv-markup-title"
            data-testid="sv-markup-title"
            {...form.getInputProps("title")}
            classNames={{
              input:
                "font-bold text-3xl bg-dark-900 pl-2 -mx-2 transition hover:bg-dark-900",
            }}
            styles={{
              input: { border: "1px solid transparent" },
            }}
            autosize
            minRows={1}
          />

          <div className="flex items-center justify-between gap-4 my-4">
            <MarkupStatusSelect {...form.getInputProps("status")} />
            <MarkupStatusButton
              size="xs"
              markup={
                {
                  ...markup,
                  status: form.values.status as Markup["status"],
                } as Markup
              }
            />
          </div>

          <MarkupDescription
            data-quick-assist-id="sv-markup-description"
            value={description}
            onChange={setDescription}
          />
        </div>

        <div>
          <div className="flex flex-row-reverse gap-2 mb-6">
            <ActionIcon
              data-testid="sv-markup-close"
              variant="transparent"
              onClick={handleClose}
            >
              <Cross1Icon />
            </ActionIcon>

            <DotsMenu data-testid="sv-markup-dots-menu">
              <DotsMenu.Dropdown>
                <DotsMenu.Item
                  data-testid="sv-markup-delete"
                  onClick={handleDelete}
                  icon={<TrashIcon className="w-4 h-4" />}
                  color="red"
                >
                  Delete markup
                </DotsMenu.Item>
              </DotsMenu.Dropdown>
            </DotsMenu>
          </div>

          <MarkupDetails form={form} />
        </div>
      </div>

      <div className="mb-2">
        <b>Activity</b>
      </div>

      <Tabs
        variant="pills"
        defaultValue="comments"
        styles={{
          panel: { marginTop: 8 },
        }}
      >
        <Tabs.List>
          <Tabs.Tab value="comments">Comments</Tabs.Tab>
          <Tabs.Tab value="logs">Logs</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel data-quick-assist-id="sv-markup-comments" value="comments">
          <MarkupComments markup={markup} mutate={() => markupCtl.mutate()} />
        </Tabs.Panel>

        <Tabs.Panel data-quick-assist-id="sv-markup-logs" value="logs">
          <MarkupLogs markup={markup} />
        </Tabs.Panel>
      </Tabs>
    </Modal>
  );
};

export { MarkupModal };
