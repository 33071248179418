import { Markup, Project } from "@server/entities";
import { atom } from "recoil";

export type TaskState = {
  project: Project | null;
  markup: Markup | null;
};

export const projectState = atom<Project | null>({
  key: "globalProjectState",
  default: null,
});

export const markupState = atom<Markup | null>({
  key: "globalMarkupState",
  default: null,
});
