import React, { FunctionComponent, useEffect, useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { LOGIN_URL } from "@common/utils/constants";
import { isInWebView } from "@common/utils/is-web-view";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user, loading } = useUser();
  const [isWebView, setIsInWebView] = useState(false);

  useEffect(() => {
    if (!loading && !user) {
      if (isInWebView()) {
        return setIsInWebView(true);
      }
      window.location.replace(LOGIN_URL);
    }
  }, [user, loading]);

  if (isWebView)
    return (
      <div className="h-[90vh] flex items-center justify-center p-10">
        <div className="text-center">
          <h1 className="text-5xl font-bold pb-4 bg-gradient-to-tr from-purple-400 to-blue-400 bg-clip-text text-transparent">
            Oops. You&apos;re using an unsupported browser :(
          </h1>
          <p className="text-xl">
            Please open this page in an external browser to continue.
          </p>
        </div>
      </div>
    );

  if (loading || !user) return <></>;

  return <>{children}</>;
};

/**
 * Only allows authorized to a route
 * @param Component
 */
export const withAuth = (Component: FunctionComponent): FunctionComponent => {
  return (props, context) => (
    <ProtectedRoute>
      <Component {...props} {...context} />
    </ProtectedRoute>
  );
};

/**
 * Only allows authorized to a route conditionally
 * @param Component
 * @param condition
 */
export function withAuthConditional<T>(
  Component: FunctionComponent<T>,
  condition: (props: T) => boolean
): FunctionComponent<T> {
  return (props, context) => {
    if (condition(props)) {
      return (
        <ProtectedRoute>
          <Component {...props} {...context} />
        </ProtectedRoute>
      );
    } else {
      return <Component {...props} {...context} />;
    }
  };
}
