import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { axiomLogger } from "@common/utils/axiom";
import { useUser } from "../../contexts/UserContext";

export const AxiomRouteLogger: React.FC = () => {
  const { user } = useUser();
  const location = useLocation();

  useEffect(() => {
    axiomLogger.log({
      type: "route",
      route: location.pathname,
      search: location.search,
      userId: user?.id,
      device: navigator.userAgent,
    });
  }, [location]);

  return <></>;
};
