import React from "react";
import moment from "moment";
import { usePlayerState } from "../../../../../contexts/Player";
import { Input } from "@common/components";
import { useProject } from "../../ProjectContext";

export const MixdownTime: React.FC = () => {
  const { project } = useProject();
  const { audio, currTime: _currTime } = usePlayerState(["currTime", "audio"]);
  const currTime = audio?.projectId === project?.id ? _currTime : 0;

  return (
    <Input
      size="xs"
      classNames={{ input: "w-16 text-md text-center bg-dark-900" }}
      value={moment().startOf("day").seconds(currTime).format("mm:ss")}
    />
  );
};
