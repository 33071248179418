import { Loader, LoadingOverlay, useMantineTheme } from "@mantine/core";
import React, { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { withContexts } from "../../contexts";
import { withContextMenu } from "./contexts/ContextMenu";
import {
  useStemViewer,
  withStemViewerContext,
} from "./contexts/StemViewerContext";
import { Markup } from "./view/markup/Markup";
import { Panel } from "./view/panel";
import { KeyboardShortcuts } from "./view/panel/KeyboardShortcuts";
import { Track } from "./view/track";
import { usePrompt } from "@common/utils/use-prompt";
import { CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet";
import { Scrollbar } from "./view/track/components/Scrollbar";

const StemViewerComponent: React.FC<any> = () => {
  const { project } = useStemViewer().track(["project"]);
  const { stems, loading, uploading } = useStemViewer().stem([
    "stems",
    "loading",
    "uploading",
  ]);
  const { markups } = useStemViewer().markup(["markups"]);
  const { stem, track } = useStemViewer().controllers;
  const theme = useMantineTheme();
  const [loaded, setLoaded] = useState(false);

  usePrompt(
    "Stems are still being uploaded. Leave anyway?",
    uploading.total > 0
  );

  useEffect(() => {
    if (!project?.id || loaded) return;
    setLoaded(true);
    const abortController = new AbortController();

    stem
      .fetch(abortController.signal)
      .then(() => track.scale(track.getScaleLimit()))
      .catch();

    return () => {
      setLoaded(false);
      abortController?.abort();
    };
  }, [project?.id]);

  return (
    <div className="flex flex-col" data-quick-assist-id="stemviewer">
      <Helmet>
        <title>{project?.name || "Loading..."} | Stem Viewer | Synqup</title>
      </Helmet>

      <LoadingOverlay
        visible={loading.total !== 0}
        loader={
          <div className="relative flex flex-col items-center gap-10 w-40">
            <CircularProgress
              variant="determinate"
              size={96}
              value={
                Math.round(
                  ((loading.done + loading.percent) / loading.total) * 100
                ) || 0
              }
              thickness={2}
              sx={{ color: theme.colors.purple[7] }}
            />
            <Loader
              className="absolute top-1/2 -translate-y-1/2"
              variant="bars"
              size="lg"
            />
          </div>
        }
        overlayColor="#000"
        className="backdrop-blur"
      />

      <LoadingBar
        height={4}
        color={theme.colors.purple[6]}
        progress={
          uploading.total > 0
            ? ((uploading.done + uploading.percent) * 100) / uploading.total
            : 100
        }
        loaderSpeed={1000}
      />

      {stems &&
        markups.map((markup) => (
          <Markup key={markup.id} markup={markup} stems={stems} />
        ))}

      <Panel stemLength={stems.length} />
      <Scrollbar />
      <KeyboardShortcuts />
      <Track />
    </div>
  );
};

const StemViewer = withContexts(
  [withContextMenu, withStemViewerContext],
  StemViewerComponent
);

export { StemViewer };
