import {
  ActionIcon,
  Avatar,
  Button,
  EmojiSelect,
  RichText,
} from "@common/components";
import Confirm from "@common/components/Confirm";
import useAsyncForm from "@common/utils/useAsyncForm";
import { Cross1Icon, TrashIcon } from "@modulz/radix-icons";
import { Delete, Download, Edit, Reply } from "@mui/icons-material";
import { UpdateMarkupCommentDto } from "@server/modules/project/markup/dto/update-markup-comment.dto";
import { MarkupComment as MarkupCommentEntity } from "@server/entities/project/markup-comment.entity";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useUser } from "../../../../../contexts/UserContext";
import {
  deleteMarkupComment,
  updateMarkupComment,
} from "../../../../../requests/project/markup";
import { RichTextReadOnly } from "@common/components/RichTextReadOnly";
import { useProject } from "../../../../projects/view/ProjectContext";
import { Badge } from "@mantine/core";
import { Check, Pencil } from "tabler-icons-react";
import { User } from "@server/entities";
import { base64ToBlob } from "../../../recoil/helpers/stem";
import { VoiceMemo } from "@common/components/VoiceMemo";
import { formatBytes } from "@common/utils/constants";
import { downloadBlob } from "../../../helpers/download-blob";
import axios from "axios";
import { notification } from "@common/utils/notification";
import { EmojiType } from "@server/entities/project/reaction.entity";
import { postReaction } from "../../../../../requests/project/reaction";

interface MarkupCommentProps {
  comment: MarkupCommentEntity;
  onReply: (user: User) => void;
  mutate?: () => Promise<void>;
}

const MarkupComment: React.FC<MarkupCommentProps> = ({
  comment,
  onReply,
  mutate,
}) => {
  const { user } = useUser();
  const { project } = useProject();
  const [del, setDel] = useState(false);
  const [resolve, setResolve] = useState(false);
  const [edit, setEdit] = useState(false);

  const form = useAsyncForm<UpdateMarkupCommentDto>({
    initialValues: comment as UpdateMarkupCommentDto,
  });

  let audio: Blob | undefined;
  if (comment.audio) audio = base64ToBlob(comment.audio, "audio/webm");

  const handleSave = async () => {
    const { error } = await form.sendForm(
      (values) =>
        updateMarkupComment(comment.id, {
          body: values.body,
        }),
      {
        resetInitial: true,
      }
    );
    if (error) return;
    setEdit(false);
  };

  const handleDownload = async () => {
    if (!comment.file) return;

    try {
      const { data } = await axios.get(comment.file.url, {
        responseType: "blob",
      });
      downloadBlob(data, comment.file.filename);
    } catch (e) {
      notification.error("Failed to download file");
    }
  };

  const handleReact = async ({ type }: { type: EmojiType }) => {
    const { error } = await postReaction(comment.id, {
      type,
      reactionFor: "markup-comment",
    });
    if (error) return notification.error(error.message);
    mutate && (await mutate());
  };

  return (
    <div
      key={comment.id}
      data-testid="sv-markup-comment"
      style={{
        background:
          comment.type === "request_changes"
            ? "rgba(255,198,0,0.1)"
            : comment.type === "resolved"
            ? "rgba(150,150,150,0.1)"
            : "transparent",
        border:
          comment.type === "request_changes"
            ? "1px solid rgba(255,198,0,0.2)"
            : comment.type === "resolved"
            ? "1px solid rgba(150,150,150,0.2)"
            : "none",
      }}
      className="relative flex items-start gap-4 w-full py-2 px-3 -mx-3 mb-2 rounded"
    >
      {comment.type === "request_changes" ? (
        <Badge
          color="yellow"
          size="xs"
          variant="light"
          className="absolute top-3 right-3"
          leftSection={<Pencil className="translate-y-0.5" size={10} />}
        >
          Changes requested
        </Badge>
      ) : comment.type === "resolved" ? (
        <Badge
          color="dark"
          size="xs"
          variant="dot"
          className="absolute top-3 right-3"
        >
          Resolved
        </Badge>
      ) : null}

      <Confirm
        icon={<Check />}
        color="green"
        title="Mark as resolved"
        content="Are you sure you want to mark this comment as resolved?"
        opened={resolve}
        onClose={() => setResolve(false)}
        onConfirm={() => {
          updateMarkupComment(comment.id, { type: "resolved" });
          setResolve(false);
        }}
      />

      <Confirm
        icon={<Delete />}
        color="red"
        title="Delete comment"
        content="Are you sure you want to delete this comment?"
        opened={del}
        onClose={() => setDel(false)}
        onConfirm={() => {
          deleteMarkupComment(comment.id);
          setDel(false);
        }}
      />

      <Avatar user={comment.user} size="sm" className="mt-2" />

      <div className="w-full">
        <div className="flex items-end gap-4">
          <b>{comment.user?.displayName}</b>

          <p className="text-dark-400 mb-[1px] text-xs">
            {dayjs(comment.createdAt).fromNow()}
          </p>
        </div>

        {audio ? (
          <VoiceMemo className="w-80" audio={audio} />
        ) : comment.file ? (
          <div className="flex items-center gap-2 w-fit">
            <p className="text-dark-400 mb-1">{comment.file.filename}</p>
            <Badge>{formatBytes(comment.file.size)}</Badge>
            <ActionIcon
              color="gray"
              variant="light"
              size="sm"
              onClick={handleDownload}
            >
              <Download className="w-4 h-4" />
            </ActionIcon>
          </div>
        ) : edit ? (
          <div className="relative">
            <RichText
              {...form.getInputProps("body")}
              classNames={{ toolbar: "hidden" }}
              className="mt-2 flex-1"
              users={project?.collaborators
                .map((c) => c.user)
                .filter((c) => c.id !== user?.id)}
            />

            <Button onClick={handleSave} size="xs" className="mt-4">
              Save
            </Button>
          </div>
        ) : (
          <RichTextReadOnly value={form.values.body || comment.body} />
        )}

        <div className="flex gap-2 mt-2 -ml-2">
          <EmojiSelect
            value={comment.reactions?.find(
              (reaction) => reaction.userId === user?.id
            )}
            reactions={comment.reactions}
            onEmojiClick={handleReact}
          />

          <Button
            color="dark"
            variant="subtle"
            size="xs"
            leftIcon={<Reply className="w-3" />}
            onClick={() => comment.user && onReply(comment.user)}
          >
            Reply
          </Button>

          {comment.user?.id === user?.id && (
            <>
              {!audio && !comment.file && (
                <Button
                  variant="subtle"
                  size="xs"
                  leftIcon={
                    edit ? (
                      <Cross1Icon className="w-3" />
                    ) : (
                      <Edit className="w-3" />
                    )
                  }
                  onClick={() => setEdit((edit) => !edit)}
                >
                  {edit ? "Cancel" : "Edit"}
                </Button>
              )}

              <Button
                color="red"
                variant="subtle"
                size="xs"
                leftIcon={<TrashIcon className="w-3" />}
                onClick={() => setDel(true)}
              >
                Delete
              </Button>

              {comment.type === "request_changes" && (
                <Button
                  color="green"
                  variant="subtle"
                  size="xs"
                  leftIcon={<Check className="w-3" />}
                  onClick={() => setResolve(true)}
                >
                  Mark as resolved
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { MarkupComment };
