import React, { useEffect, useRef, useState } from "react";
import { Mixdown, Project } from "@server/entities";
import { usePlayer, usePlayerState } from "../../../../../contexts/Player";
import {
  ActionIcon,
  Button,
  Card,
  DotsMenu,
  Input,
  Modal,
  Select,
  Popover,
} from "@common/components";
import {
  BarChart,
  PauseRounded,
  PlayArrowRounded,
  Upload,
} from "@mui/icons-material";
import { useProject } from "../../ProjectContext";
import useAsyncForm from "@common/utils/useAsyncForm";
import { UpdateMixdownDto } from "@server/modules/project/mixdown/dto/update-mixdown.dto";
import { object, string } from "yup";
import {
  deleteMixdown,
  getMixdownListens,
  registerListen,
  updateMixdown,
} from "../../../../../requests/project/mixdown";
import { notification } from "@common/utils/notification";
import { MixdownTime } from "./MixdownTime";
import { ArrowsExchange, Edit, Trash } from "tabler-icons-react";
import Confirm from "@common/components/Confirm";
import { MixdownSnippetModal } from "./mixdown-snippet/MixdownSnippetModal";
import { Share2Icon } from "@modulz/radix-icons";
import Skeleton from "@common/components/Skeleton";
import { formatNumber } from "@common/utils/constants";

interface MixdownPanelProps {
  buffer: AudioBuffer;
  currMixdown?: Mixdown;
  project: Partial<Omit<Project, "collaborators">>;
  file?: File;
  onUpload?: () => void;
  onMixdownChange: (mixdownId: string | null) => void;
  onMixdownCompare: (mixdownId: string) => void;
}

export const MixdownPanel: React.FC<MixdownPanelProps> = ({
  buffer,
  currMixdown,
  project,
  file,
  onUpload,
  onMixdownChange,
  onMixdownCompare,
}) => {
  const { view, mutate } = useProject();
  const { load, playPause, unload } = usePlayer();
  const { audio, playing: _playing } = usePlayerState(["audio", "playing"]);
  const playing = _playing && audio?.projectId === project.id;
  const [menuOpen, setMenuOpen] = useState(false);
  const [rename, setRename] = useState(false);
  const [shareMixdownSnippet, setShareMixdownSnippet] = useState(false);
  const [deleteMix, setDelete] = useState(false);
  const [listens, setListens] = useState(0);
  const [loadingListens, setLoadingListens] = useState(false);
  const listenRef = useRef<NodeJS.Timeout>();

  const form = useAsyncForm<UpdateMixdownDto>({
    initialValues: {
      name: currMixdown?.name,
    },
    schema: object().shape({
      name: string().required("Mixdown name is required"),
    }),
  });

  // Fetch listens
  useEffect(() => {
    if (!currMixdown) return;

    setLoadingListens(true);
    getMixdownListens(currMixdown.id).then(({ data }) => {
      if (data) setListens(data);
      setLoadingListens(false);
    });

    return () => {
      if (listenRef.current) clearTimeout(listenRef.current);
    };
  }, [currMixdown]);

  const handlePlayPause = () => {
    if (listenRef.current) clearTimeout(listenRef.current);

    const src =
      (file ? URL.createObjectURL(file) : null) || currMixdown?.file?.url;
    const name = project.name || file?.name || "Unknown";

    if (playing) {
      return playPause();
    }

    if (!src) {
      return;
    }

    if (audio?.file && audio.file === file) {
      return playPause();
    } else {
      if (listenRef.current) clearTimeout(listenRef.current);
      listenRef.current = setTimeout(async () => {
        listenRef.current = undefined;
        if (!currMixdown) return;
        await registerListen(currMixdown.id);
      }, 10000);

      load({
        src,
        name,
        file,
        artist: project.artist,
        artwork: project.albumArt,
        projectId: project.id,
      });
    }
  };

  const handleSave = async () => {
    if (!currMixdown) return;

    const { error } = await form.sendForm(async (values) =>
      updateMixdown(currMixdown.id, values)
    );

    if (error) return notification.error(error.message);

    setRename(false);
    await mutate();
  };

  const handleDelete = async () => {
    if (!currMixdown) return;

    const { error } = await deleteMixdown(currMixdown.id);

    if (error) return notification.error(error.message);

    setDelete(false);
    onMixdownChange(null);
    await mutate();
  };

  const audioSrc =
    currMixdown?.file?.url || (file ? URL.createObjectURL(file) : null);

  return (
    <Card className="relative flex items-center justify-center mb-24 md:mb-12 rounded-tr-none rounded-tl-none p-2">
      <div className="w-full absolute flex items-center gap-2 left-2">
        <MixdownTime />

        {project.mixdowns && project.mixdowns.length > 0 && (
          <Select
            data-quick-assist-id="mixdown-select"
            className="-my-2 w-32 md:w-48 ml-auto mr-4 md:ml-0"
            size="xs"
            data={
              project.mixdowns?.map((mixdown) => ({
                label: mixdown.name,
                value: mixdown.id,
              })) || []
            }
            value={currMixdown?.id}
            onChange={(value) => {
              unload();
              if (value) onMixdownChange(value);
            }}
          />
        )}
      </div>

      <ActionIcon
        data-testid="mixdown-play-pause"
        variant={playing ? "light" : "filled"}
        color={playing ? "gray" : "primary"}
        onClick={handlePlayPause}
      >
        {playing ? <PauseRounded /> : <PlayArrowRounded />}
      </ActionIcon>

      <div className="absolute right-2 top-16 md:top-auto flex items-center gap-2">
        {currMixdown &&
          (loadingListens ? (
            <Skeleton visible />
          ) : (
            <div className="flex items-center gap-2 mr-2">
              <BarChart className="text-indigo-500" />
              {formatNumber(listens)} listens
            </div>
          ))}

        {view !== "viewer" && (
          <Button
            data-testid="mixdown-upload"
            color="gray"
            variant="light"
            leftIcon={<Upload className="w-4 h-4" />}
            onClick={onUpload}
            size="xs"
          >
            Upload
          </Button>
        )}

        {project.mixdowns && project.mixdowns.length > 1 && (
          <div id="mixdown-compare-demo-flag" className="absolute w-1 h-1" />
        )}

        {currMixdown && project.mixdowns && (
          <DotsMenu
            opened={menuOpen}
            onOpen={() => setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}
            data-quick-assist-id="mixdown-menu"
            closeOnItemClick={false}
            closeOnClickOutside={false}
          >
            <DotsMenu.Dropdown>
              <Popover
                position="left-start"
                withArrow={false}
                disabled={project.mixdowns.length < 2}
                closeOnClickOutside={false}
              >
                <Popover.Target>
                  <DotsMenu.Item
                    id="mixdown-compare-button"
                    data-quick-assist-id="mixdown-compare-button"
                    icon={<ArrowsExchange className="w-4 h-4" />}
                    disabled={project.mixdowns.length < 2}
                  >
                    Compare versions
                  </DotsMenu.Item>
                </Popover.Target>

                <Popover.Dropdown>
                  <p className="text-xs p-2 mb-0">Compare with:</p>
                  <div className="flex flex-col gap-1">
                    {project.mixdowns
                      ?.filter((mix) => mix.id !== currMixdown?.id)
                      .map((mixdown) => (
                        <Button
                          data-quick-assist-id="mixdown-compare-item"
                          variant="subtle"
                          size="sm"
                          key={mixdown.id}
                          onClick={() => {
                            setMenuOpen(false);
                            console.log("reached here 1");
                            onMixdownCompare(mixdown.id);
                          }}
                        >
                          {mixdown.name}
                        </Button>
                      ))}
                  </div>
                </Popover.Dropdown>
              </Popover>

              <DotsMenu.Item
                onClick={() => {
                  setMenuOpen(false);
                  setShareMixdownSnippet(true);
                }}
                icon={<Share2Icon className="w-4 h-4" />}
              >
                Share mixdown
              </DotsMenu.Item>

              {view !== "viewer" && (
                <>
                  <DotsMenu.Item
                    onClick={() => {
                      setMenuOpen(false);
                      setRename(true);
                    }}
                    icon={<Edit className="w-4 h-4" />}
                  >
                    Rename mixdown
                  </DotsMenu.Item>

                  <DotsMenu.Item
                    onClick={() => {
                      setMenuOpen(false);
                      setDelete(true);
                    }}
                    icon={<Trash className="w-4 h-4" />}
                    color="red"
                  >
                    Delete mixdown
                  </DotsMenu.Item>
                </>
              )}
            </DotsMenu.Dropdown>
          </DotsMenu>
        )}
      </div>

      <Confirm
        title="Delete mixdown"
        content="Are you sure you want to delete this mixdown?"
        opened={deleteMix}
        onClose={() => setDelete(false)}
        onConfirm={handleDelete}
        color="red"
        icon={<Trash className="w-5 h-5" />}
      />

      <Modal
        opened={rename}
        onClose={() => setRename(false)}
        size="sm"
        title={<h2>Rename mixdown</h2>}
      >
        <Input
          {...form.getInputProps("name")}
          autoFocus
          label="New mixdown name"
        />

        <div className="flex justify-end gap-4 mt-4">
          <Button onClick={handleSave} disabled={!form.values.name}>
            Rename
          </Button>
        </div>
      </Modal>

      {audioSrc && (
        <MixdownSnippetModal
          buffer={buffer}
          opened={shareMixdownSnippet}
          onClose={() => setShareMixdownSnippet(false)}
          audio={audioSrc}
        />
      )}
    </Card>
  );
};
