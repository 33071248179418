import React, { useEffect, useState } from "react";
import { useMarkups } from "../../../../requests/project/markup";
import { TaskBoard, TaskBoardState } from "../../../tasks/components/TaskBoard";
import { useLocalStorage } from "@mantine/hooks";
import { Button } from "@common/components";
import { Plus } from "tabler-icons-react";
import { useTasks } from "../../../../contexts/Tasks/TaskContext";

interface ProjectTasksProps {
  projectId: string;
}

export const ProjectTasks: React.FC<ProjectTasksProps> = ({ projectId }) => {
  const [assignedByMe, setAssignedByMe] = useLocalStorage({
    key: "tasks.assignedByMe",
    defaultValue: false,
  });
  const [assignedToMe, setAssignedToMe] = useLocalStorage({
    key: "tasks.assignedToMe",
    defaultValue: false,
  });

  const { createTask, mutateTasks } = useTasks();

  const { data, isValidating, mutate } = useMarkups(projectId, {
    withApproved: true,
    type: ["task", "markup"],
    limit: 100,
    assignedByMe: assignedByMe ? true : undefined,
    assignedToMe: assignedToMe ? true : undefined,
  });

  const [state, setState] = useState<TaskBoardState>();

  // Mutate on task create
  useEffect(() => {
    mutate();
  }, [mutateTasks]);

  // Mutate on data change
  useEffect(() => {
    setState({
      columns: [
        {
          status: "pending",
          markups:
            data?.data.filter((markup) => markup.status === "pending") || [],
        },
        {
          status: "needs_approval",
          markups:
            data?.data.filter((markup) => markup.status === "needs_approval") ||
            [],
        },
        {
          status: "approved",
          markups:
            data?.data.filter((markup) => markup.status === "approved") || [],
        },
      ],
    });
  }, [data]);

  return (
    <div>
      <div className="flex justify-between mb-4">
        <div className="flex gap-2">
          <Button
            data-testid="task-filter-assigned-to-me"
            variant={assignedToMe ? "filled" : "light"}
            color={assignedToMe ? "primary" : "gray"}
            onClick={() => setAssignedToMe((prev) => !prev)}
          >
            Assigned to me
          </Button>

          <Button
            data-testid="task-filter-assigned-by-me"
            variant={assignedByMe ? "filled" : "light"}
            color={assignedByMe ? "primary" : "gray"}
            onClick={() => setAssignedByMe((prev) => !prev)}
          >
            Assigned by me
          </Button>
        </div>

        <Button
          data-testid="create-task"
          data-quick-assist-id="create-task"
          leftIcon={<Plus className="w-5 h-5" />}
          onClick={() => createTask()}
        >
          Create task
        </Button>
      </div>

      <TaskBoard loading={isValidating} state={state} />
    </div>
  );
};
