import {
  CreateCommentDto,
  CreateNoteDto,
  UpdateNoteDto,
} from "@server/modules/project/note/dto";
import { Note } from "@server/entities/project";
import { useDelete, usePatch, usePost, useStickySWR, useSWR } from "../helpers";

export const postNote = async (data: CreateNoteDto) =>
  usePost<Note>("/project/note", data);

export const postComment = async (id: string, data: CreateCommentDto) =>
  usePost<null>(`/project/note/${id}`, data);

export const patchNote = async (id: string, data: UpdateNoteDto) =>
  usePatch<Note>(`/project/note/${id}`, data);

export const deleteNote = (id: string) =>
  useDelete<null>(`/project/note/${id}`);

export const getProjectNotes = (projectId: string) =>
  useSWR<Note[]>(`/project/note/project/${projectId}`);

export const useNote = (id: string) =>
  useStickySWR<Note>(`/project/note/${id}`);
