import React from "react";
import { Card } from "@common/components";
import { MediaLibrary as MediaLibraryComponent } from "../../../contexts/MediaLibrary/MediaLibrary";

export const MediaLibrary: React.FC = () => {
  return (
    <div className="container p-4">
      <Card id="media-library" data-quick-assist-id="media-library">
        <h2>Media Library</h2>
        <MediaLibraryComponent queryable={true} />
      </Card>
    </div>
  );
};
