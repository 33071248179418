import React from "react";
import { UpdateProjectDto } from "@server/modules/project/project/dto";
import { UseAsyncFormReturnType } from "@common/utils/useAsyncForm";
import { isEmpty } from "lodash";
import InstagramSvg from "@res/images/instagram.svg";
import SpotifySvg from "@res/images/spotify.svg";
import TikTokSvg from "@res/images/tiktok.svg";
import YoutubeSvg from "@res/images/youtube.svg";
import SoundcloudSvg from "@res/images/soundcloud.svg";
import { LinkedAccount } from "@server/entities";
import { Button } from "@common/components";
import { Plus } from "tabler-icons-react";
import { Link } from "react-router-dom";
import { useProject } from "../ProjectContext";

interface ProjectSocialIconsProps {
  form: UseAsyncFormReturnType<UpdateProjectDto>;
}

export const ProjectSocialIcons: React.FC<ProjectSocialIconsProps> = ({
  form,
}) => {
  const { view } = useProject();

  const getLinkedAccountUrl = (type: LinkedAccount["type"]) => {
    return form.values?.linkedAccounts?.find((acc) => acc.type === type)?.url;
  };

  if (form.values.linkedAccounts && form.values.linkedAccounts.length > 0) {
    return (
      <div className="container h-0 p-0 overflow-visible relative">
        <div className="absolute top-10 right-0 z-10 flex items-center gap-2 bg-dark-700 bg-opacity-30 p-3 pb-1 backdrop-blur rounded">
          {!isEmpty(getLinkedAccountUrl("instagram")) && (
            <a href={getLinkedAccountUrl("instagram")} target="_blank">
              <img className="w-5 h-5" src={InstagramSvg} alt="instagram" />
            </a>
          )}

          {!isEmpty(getLinkedAccountUrl("spotify")) && (
            <a href={getLinkedAccountUrl("spotify")} target="_blank">
              <img className="w-5 h-5" src={SpotifySvg} alt="spotify" />
            </a>
          )}

          {!isEmpty(getLinkedAccountUrl("tiktok")) && (
            <a href={getLinkedAccountUrl("tiktok")} target="_blank">
              <img className="w-5 h-5" src={TikTokSvg} alt="tiktok" />
            </a>
          )}

          {!isEmpty(getLinkedAccountUrl("youtube")) && (
            <a href={getLinkedAccountUrl("youtube")} target="_blank">
              <img className="w-5 h-5" src={YoutubeSvg} alt="youtube" />
            </a>
          )}

          {!isEmpty(getLinkedAccountUrl("soundcloud")) && (
            <a href={getLinkedAccountUrl("soundcloud")} target="_blank">
              <img className="w-5 h-5" src={SoundcloudSvg} alt="soundcloud" />
            </a>
          )}
        </div>
      </div>
    );
  }

  if (view === "viewer") {
    return null;
  }

  return (
    <div className="container h-0 p-0 overflow-visible relative">
      <Link
        className="absolute top-10 right-0 z-10"
        to={`/p/${form.values.permalink}/settings`}
      >
        <Button
          size="xs"
          color="pink"
          variant="light"
          leftIcon={<Plus className="w-4 h-4" />}
        >
          Add socials
        </Button>
      </Link>
    </div>
  );
};
