import React from "react";
import { getLogs } from "../../../requests/project/logs";
import { Avatar, Card } from "@common/components";
import { Avatar as MantineAvatar } from "@mantine/core";
import { Badge, Timeline } from "@mantine/core";
import dayjs from "dayjs";
import { getLogColor } from "../../stemviewer/view/markup/components/MarkupLogs";
import { humanize } from "underscore.string";
import { useNavigate } from "react-router-dom";

export const RecentLogs: React.FC = () => {
  const navigate = useNavigate();
  const { data } = getLogs();

  const handleLogClick = (log) => {
    if (log.projectId) {
      navigate(`/p/${log.project.permalink}`);
    } else if (log.markupId) {
      navigate(`?markupId=${log.markup?.id}`);
    }
  };

  return (
    <Card className="bg-dark-900 p-6" data-quick-assist-id="recent-logs">
      <h2 className="mb-10">Recent activity</h2>

      <Timeline
        classNames={{
          itemBody:
            "-translate-y-4 rounded-md transition cursor-pointer hover:bg-dark-700 p-2 -m-2 -mb-4",
        }}
        lineWidth={2}
        active={data?.data.length}
      >
        {data && data?.data.length !== 0 ? (
          data?.data.map((log) => (
            <Timeline.Item
              data-testid="log-item"
              key={log.id}
              bullet={<Avatar size="sm" user={log.user} />}
              bulletSize={20}
              color={getLogColor(log.type)}
              title={
                <p className="text-gray-500 text-xs mb-0">
                  {dayjs(log.createdAt).fromNow()}
                </p>
              }
              onClick={() => handleLogClick(log)}
            >
              <p className="mb-2 -mt-1" data-testid="log-body">
                {log.user?.displayName} {log.body}
              </p>
              <div className="flex items-center gap-1">
                <div className="flex items-center gap-2 rounded p-1 px-2">
                  <MantineAvatar
                    size="xs"
                    src={log.markup?.project?.albumArt || log.project?.albumArt}
                  />
                  <span className="uppercase font-medium text-[0.75rem] max-w-[12rem] truncate">
                    {log.markup?.project?.name || log.project?.name}
                  </span>
                </div>
                <Badge
                  size="sm"
                  variant="dot"
                  radius="sm"
                  color={getLogColor(log.type)}
                >
                  {humanize(log.type)}
                </Badge>
              </div>
            </Timeline.Item>
          ))
        ) : (
          <p className="text-gray-500 -m-3 -mt-6">No logs yet!</p>
        )}
      </Timeline>
    </Card>
  );
};
