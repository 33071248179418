import { Markup } from "@server/entities/project";
import { useSetRecoilState } from "recoil";
import { useSockets } from "../../../../contexts/SocketContext";
import { useSocketListener } from "../../../../requests/helpers/use-socket-listener";
import { getMarkup, getMarkups } from "../../../../requests/project/markup";
import { useProject } from "../../../projects/view/ProjectContext";
import { useStemViewer } from "../../contexts/StemViewerContext";
import { PartialSetterOrUpdater } from "../helpers/controller";
import { MarkupState } from "./markup.atom";
import { markupState } from "./markup.selector";

export const useMarkupSocket = () => {
  const { svSocket: socket } = useSockets();
  const { project } = useProject();
  const { view } = useStemViewer().markup(["view"]);
  const { stem } = useStemViewer().controllers;

  const setMarkup: PartialSetterOrUpdater<MarkupState> =
    useSetRecoilState(markupState);

  // Handle markup creation
  useSocketListener(socket, "markup:create", (data: Markup) => {
    return setMarkup((state) => {
      // If markup already exists, ignore
      if (state.markups.some((markup) => markup.id === data.id)) return state;
      return { markups: [...state.markups, data] };
    });
  });

  // Handle markup updates
  useSocketListener(
    socket,
    "markup:update",
    async (data: Markup) => {
      if (view?.id === data?.id) setMarkup({ view: data });

      return setMarkup((state) => ({
        markups: state.markups.map((markup) =>
          markup.id === data?.id ? { ...markup, ...data } : markup
        ),
      }));
    },
    [view, stem]
  );

  // Handle markup deletes
  useSocketListener(socket, "markup:delete", (id: string) => {
    if (view?.id === id) setMarkup({ view: undefined });

    return setMarkup((state) => ({
      markups: state.markups.filter((markup) => markup.id !== id),
    }));
  });

  // Handle markup fetch
  useSocketListener(socket, "markup:fetch", async (id: string) => {
    const { data: view } = await getMarkup(id);
    if (view) return setMarkup({ view });
  });

  // Handle markup fetch all
  useSocketListener(socket, "markup:fetchAll", async () => {
    if (!project) return;
    const { data: markups } = await getMarkups(project.id, { type: "markup" });
    if (markups?.data) return setMarkup({ markups: markups.data });
  });
};
