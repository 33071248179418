import React from "react";
import { RecentLogs } from "../components/RecentLogs";
import { Helmet } from "react-helmet";
import { RecentProjects } from "../components/RecentProjects";
import { withAuth } from "../../routes/ProtectedRoute";
import { TasksChecklist } from "../components/TasksChecklist";

export const Home: React.FC = withAuth(() => {
  return (
    <div className="relative flex md:flex-row flex-col-reverse gap-4 p-4">
      <Helmet>
        <title>Dashboard | Synqup</title>
      </Helmet>

      <div className="md:w-1/3 flex flex-col gap-4">
        <TasksChecklist />
        <RecentLogs />
      </div>

      <div className="flex-1">
        <RecentProjects />
      </div>
    </div>
  );
});
