import { Classification } from "@server/entities/user";
import audioEngineerImage from "@res/images/classifications/audio-engineer.png";
import arrangerImage from "@res/images/classifications/arranger.png";
import composerImage from "@res/images/classifications/composer.png";
import djImage from "@res/images/classifications/dj.png";
import drumsImage from "@res/images/classifications/drums.png";
import sessionGuitaristImage from "@res/images/classifications/session-guitarist.png";
import sessionBassistImage from "@res/images/classifications/session-bassist.png";
import producerImage from "@res/images/classifications/producer.png";
import vocalistImage from "@res/images/classifications/vocalist.png";

export const __prod__ = process.env.NODE_ENV === "production";

export const DEFAULT_ARTWORK =
  "https://dev.synqup.com.au/images/no-album-art.png";

export const DEFAULT_COVER = "https://dev.synqup.com.au/images/cover.png";

export function formatBytes(bytes: number, decimals?: number) {
  if (bytes == 0) return "0 Bytes";
  let k = 1024,
    dm = decimals || 1,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function formatNumber(num: number) {
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, "") + "k";
  }
  return num.toString();
}

export const CLASSIFICATIONS: Array<{
  image: string;
  value: string;
  label: string;
}> = [
  {
    image: audioEngineerImage,
    label: "Audio Engineer",
    value: "Audio Engineer",
  },
  {
    image: arrangerImage,
    label: "Arranger",
    value: "Arranger",
  },
  {
    image: composerImage,
    label: "Composer",
    value: "Composer",
  },
  {
    image: djImage,
    label: "DJ",
    value: "DJ",
  },
  {
    image: drumsImage,
    label: "Drums",
    value: "Drums",
  },
  {
    image: producerImage,
    label: "Producer",
    value: "Producer",
  },
  {
    image: sessionBassistImage,
    label: "Session Bassist",
    value: "Session Bassist",
  },
  {
    image: sessionGuitaristImage,
    label: "Session Guitarist",
    value: "Session Guitarist",
  },
  {
    image: vocalistImage,
    label: "Vocalist",
    value: "Vocalist",
  },
];

export const LEVELS: { label: string; value: Classification["level"] }[] = [
  { value: "emerging", label: "Emerging" },
  { value: "established", label: "Established" },
  { value: "well-known", label: "Well-known" },
  { value: "legendary", label: "Legendary" },
];

export const LOGIN_URL = `${
  __prod__ ? "" : "http://localhost:5001"
}/api/auth/auth0/login?redirect_uri=${window.location.href}`;
