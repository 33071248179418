import { CreateMarkupCommentDto } from "@server/modules/project/markup/dto/create-markup-comment.dto";
import { CreateMarkupDto } from "@server/modules/project/markup/dto/create-markup.dto";
import { UpdateMarkupCommentDto } from "@server/modules/project/markup/dto/update-markup-comment.dto";
import { UpdateMarkupDto } from "@server/modules/project/markup/dto/update-markup.dto";
import { Markup } from "../../../../server/src/entities/project";
import { MarkupComment } from "@server/entities/project/markup-comment.entity";
import { MarkupState } from "../../modules/stemviewer/recoil/markup/markup.atom";
import {
  useDelete,
  useGet,
  useMultipartForm,
  usePatch,
  usePost,
  useStickyStaleSWR,
  useStickySWR,
} from "../helpers";
import { QueryMarkupDto } from "@server/modules/project/markup/dto/query-markup.dto";
import { PaginatedListDto } from "@server/dto/paginated-list.dto";
import { UpdateMarkupsDto } from "@server/modules/project/markup/dto/update-markups.dto";

export const createMarkup = async (data: CreateMarkupDto) =>
  usePost<Markup, CreateMarkupDto>("/project/markup", data);

export const updateMarkups = async (data: UpdateMarkupsDto) =>
  usePatch<null, UpdateMarkupsDto>(`/project/markup`, data);

export const updateMarkup = async (id: string, data: UpdateMarkupDto) =>
  usePatch<null, UpdateMarkupDto>(`/project/markup/${id}`, data);

export const revertMarkup = async (id: string) =>
  usePatch<null>(`/project/markup/revert/${id}`);

export const deleteMarkup = (id: string) =>
  useDelete<null>(`/project/markup/${id}`);

export const getMarkup = (id: string) =>
  useGet<MarkupState["view"]>(`/project/markup/${id}`);

export const useMyMarkups = (query: QueryMarkupDto) =>
  useStickySWR<PaginatedListDto<Markup>>(`/project/markup/`, query);

export const getMyMarkups = (query: QueryMarkupDto) =>
  useGet<PaginatedListDto<Markup>>(`/project/markup`, query);

export const useMarkups = (projectId: string, query?: QueryMarkupDto) =>
  useStickyStaleSWR<PaginatedListDto<Markup>>(
    `/project/markup/project/${projectId}`,
    query
  );

export const getMarkups = (projectId: string, query?: QueryMarkupDto) =>
  useGet<PaginatedListDto<Markup>>(
    `/project/markup/project/${projectId}`,
    query
  );

export const createMarkupComment = async (data: CreateMarkupCommentDto) =>
  useMultipartForm<MarkupComment, CreateMarkupCommentDto>(
    "/project/markup/comment",
    data
  );

export const updateMarkupComment = async (
  id: string,
  data: UpdateMarkupCommentDto
) =>
  useMultipartForm<null, UpdateMarkupCommentDto>(
    `/project/markup/comment/${id}`,
    data
  );

export const deleteMarkupComment = (id: string) =>
  useDelete<null>(`/project/markup/comment/${id}`);
